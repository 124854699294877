// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Bootstrapカスタマイズ
@import 'custom';

// jquery -ui                                                                                                                                                                                                      
// @import '~jquery-ui/themes/base/all.css';

// Font Awesome
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/solid';

// Select2
@import "~select2/dist/css/select2.css";

// Select2 Bootstrap5 Theme
@import "~select2-bootstrap-5-theme/src/include-all";