// コントラスト値（カスタムテーマカラーの文字色を白にするため。注：WCAG 2.0で許容されているRatioは3, 4.5, 7）
$min-contrast-ratio: 2.5;   // 4 → 2.5

// テーマカラー変更
$primary: dodgerblue;
$success: #4CAF50;
$danger: #F44336;

// フォント
$font-family-sans-serif: 'Helvetica Neue', Arial, 'Hiragino Kaku Gothic ProN', 'Hiragino Sans', Meiryo, sans-serif;
$font-size-base: 1.6rem;
$line-height-base: 1.6;